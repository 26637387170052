import React from "react";

import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import { Helmet } from 'react-helmet'
import { Controller, Scene } from 'react-scrollmagic';
import { Link } from "gatsby";

import Layout from '../components/layout';
import styles from './home.module.scss';

import HomeScroll from '../components/home-scroll';
import homeScrollStyles from '../components/home-scroll/styles.module.scss';

import ExtraLinksNav from '../components/extra-links-nav';

import logo from '../images/general/logo.svg';
import boomwriter from '../images/home/boomwriter@2x.jpg';
import quotient from '../images/home/quotient@2x.jpg';
import ginfizzvan from '../images/home/ginfizz@2x.jpg';
import glutenfreesheff from '../images/home/glutenfreesheff@2x.jpg';
import goapps from '../images/home/goapps@2x.jpg';

const Home=() => {
  return(
    <>
      <Helmet>
          <title>Welcome to BWalkerCreative: Ben Walker's Digital Portfolio of UX/UI Design, Web Design and Front-End Development</title>
          <meta name="description" content="Welcome to Ben Walker's digital portfolio of UI/UX Design, Web Design and Front-End Development projects." />
      </Helmet>
      <Layout>
        <Controller>
            <section className={styles.hero}>
              <ExtraLinksNav />
              <Container>
                <Row>
                  <Col className="pt-5 pt-md-10">
                    <p className="standOut">
                      <span className={`mb-half d-inline-block p-half ${styles.heroText}`}>Hello World, my name is <span className={`highlight fontWeight-bold ${styles.heroTextHighlight}`}>Ben Walker</span></span><br />
                      <span className={`d-inline-block p-half ${styles.heroText} ${styles.heroTextSecond}`}>Welcome to <span className="fontWeight-bold">bwalkercreative</span></span>
                    </p>
                  </Col>
                </Row>
              </Container>
              <img className={styles.heroLogo} src={logo} alt=""/>
            </section>
            <section id="Project1" className={`panelPrimaryLight fullHeight-md d-flex align-items-center fullWidth ${styles.projectPanel}`}>
              <Container className="py-3 py-md-0 pl-md-4 pl-lg-5 pl-xl-4">
                    <Row className="layer-up">
                      <Col xs="auto">
                        <span className={`fontWeight-bold ml-md-1 ml-lg-3 ${styles.projectNumber} ${styles.projectPrimaryText}`}>01/</span>
                      </Col>
                      <Col xs="auto" className="ml-auto d-md-none align-items-end d-flex pb-half">
                        <div className={`${styles.projectSwatch} ${styles.project1Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project1Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project1Swatch3}`}></div>
                      </Col>
                    </Row>
                    <Row className="my-1 mt-md-n3 mb-md-n2">
                      <Col>
                        <img src={boomwriter} alt="" />
                      </Col>
                      <Col xs="auto" className="d-none d-md-block pl-md-1 pl-lg-3 pt-lg-2">
                        <div className={`${styles.projectSwatch} ${styles.project1Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project1Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project1Swatch3}`}></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        <Link className={`${styles.projectTitle} ${styles.projectTitleLink} ${styles.projectTitleLinkPrimary}`} to="">
                          <h2>BoomWriter</h2>
                        </Link>
                      </Col>
                    </Row>
              </Container>
            </section>
            <section id="Project2" className={`panelSecondaryLight fullHeight-md d-flex align-items-center fullWidth ${styles.projectPanel}`}>
              <Container className="py-3 py-md-0 pl-md-4 pl-lg-5 pl-xl-4">
                    <Row className="layer-up">
                      <Col xs="auto" md={{}} className="text-md-right">
                        <span className={`fontWeight-bold mr-md-1 mr-lg-3 ${styles.projectNumber} ${styles.projectSecondaryText}`}>02/</span>
                      </Col>
                      <Col xs="auto" className="ml-auto d-md-none align-items-end d-flex pb-half">
                        <div className={`${styles.projectSwatch} ${styles.project2Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project2Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project2Swatch3}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project2Swatch4}`}></div>
                      </Col>
                    </Row>
                    <Row className="my-1 mt-md-n3 mb-md-n2">
                      <Col xs="auto" className="d-none d-md-block pr-md-1 pr-lg-3 pt-lg-2">
                        <div className={`${styles.projectSwatch} ${styles.project2Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project2Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project2Swatch3}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project2Swatch4}`}></div>
                      </Col>
                      <Col>
                        <img src={quotient} alt="" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right text-md-left">
                        <Link className={`${styles.projectTitle} ${styles.projectTitleLink} ${styles.projectTitleLinkSecondary}`} to="">
                          <h2>Quotient</h2>
                        </Link>
                      </Col>
                    </Row>
              </Container>
            </section>
            <section id="Project3" className={`panelPrimaryLight fullHeight-md d-flex align-items-center fullWidth ${styles.projectPanel}`}>
              <Container className="py-3 py-md-0 pl-md-4 pl-lg-5 pl-xl-4">
                    <Row className="layer-up">
                      <Col xs="auto">
                        <span className={`fontWeight-bold ml-md-1 ml-lg-3 ${styles.projectNumber} ${styles.projectPrimaryText}`}>03/</span>
                      </Col>
                      <Col xs="auto" className="ml-auto d-md-none align-items-end d-flex pb-half">
                        <div className={`${styles.projectSwatch} ${styles.project3Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project3Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project3Swatch3}`}></div>
                      </Col>
                    </Row>
                    <Row className="my-1 mt-md-n3 mb-md-n2">
                      <Col>
                        <img src={ginfizzvan} alt="" />
                      </Col>
                      <Col xs="auto" className="d-none d-md-block pl-md-1 pl-lg-3 pt-lg-2">
                        <div className={`${styles.projectSwatch} ${styles.project3Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project3Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project3Swatch3}`}></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        <Link className={`${styles.projectTitle} ${styles.projectTitleLink} ${styles.projectTitleLinkPrimary}`} to="">
                          <h2>GinFizz Van</h2>
                        </Link>
                      </Col>
                    </Row>
              </Container>
            </section>
            <section id="Project4" className={`panelSecondaryLight fullHeight-md d-flex align-items-center fullWidth ${styles.projectPanel}`}>
              <Container className="py-3 py-md-0 pl-md-4 pl-lg-5 pl-xl-4">
                    <Row className="layer-up">
                      <Col xs="auto" md={{}} className="text-md-right">
                        <span className={`fontWeight-bold mr-md-1 mr-lg-3 ${styles.projectNumber} ${styles.projectSecondaryText}`}>04/</span>
                      </Col>
                      <Col xs="auto" className="ml-auto d-md-none align-items-end d-flex pb-half">
                        <div className={`${styles.projectSwatch} ${styles.project4Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project4Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project4Swatch3}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project4Swatch4}`}></div>
                      </Col>
                    </Row>
                    <Row className="my-1 mt-md-n3 mb-md-n2">
                      <Col xs="auto" className="d-none d-md-block pr-md-1 pr-lg-3 pt-lg-2">
                        <div className={`${styles.projectSwatch} ${styles.project4Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project4Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project4Swatch3}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project4Swatch4}`}></div>
                      </Col>
                      <Col>
                        <img src={glutenfreesheff} alt="" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right text-md-left">
                        <Link className={`${styles.projectTitle} ${styles.projectTitleLink} ${styles.projectTitleLinkSecondary}`} to="">
                          <h2>GlutenFreeSheff</h2>
                        </Link>
                      </Col>
                    </Row>
              </Container>
            </section>
            <section id="Project5" className={`panelPrimaryLight fullHeight-md d-flex align-items-center fullWidth ${styles.projectPanel}`}>
              <Container className="py-3 py-md-0 pl-md-4 pl-lg-5 pl-xl-4">
                    <Row className="layer-up">
                      <Col xs="auto">
                        <span className={`fontWeight-bold ml-md-1 ml-lg-3 ${styles.projectNumber} ${styles.projectPrimaryText}`}>05/</span>
                      </Col>
                      <Col xs="auto" className="ml-auto d-md-none align-items-end d-flex pb-half">
                        <div className={`${styles.projectSwatch} ${styles.project5Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project5Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project5Swatch3}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project5Swatch4}`}></div>
                      </Col>
                    </Row>
                    <Row className="my-1 mt-md-n3 mb-md-n2">
                      <Col>
                        <img src={goapps} alt="" />
                      </Col>
                      <Col xs="auto" className="d-none d-md-block pl-md-1 pl-lg-3 pt-lg-2">
                        <div className={`${styles.projectSwatch} ${styles.project5Swatch1}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project5Swatch2}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project5Swatch3}`}></div>
                        <div className={`${styles.projectSwatch} ${styles.project5Swatch4}`}></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        <Link className={`${styles.projectTitle} ${styles.projectTitleLink} ${styles.projectTitleLinkPrimary}`} to="">
                          <h2>GoApps</h2>
                        </Link>
                      </Col>
                    </Row>
              </Container>
            </section>
            <Scene duration={0} classToggle={['.homeScroll', `${homeScrollStyles.scrolled}`]} triggerElement="#Project1" offset={-50}>
              <HomeScroll className="homeScroll d-none d-md-block" />
            </Scene>
          </Controller>
      </Layout>
    </>
  )
}

export default Home
