import React from "react";

import styles from './styles.module.scss';

const ExtraLinksNav=() => {
    return(
        <nav className={styles.extraLinks}>
            <ul className={styles.extraLinksList}>
                <li className={styles.extraLinksListItem}>
                    <a className={styles.extraLinksListItemLink} href="https://dl.dropboxusercontent.com/s/oigrw1yexmn0rb9/ben-walker-portfolio.pdf?dl=1">Portfolio</a>
                </li>
            </ul>
        </nav>
    )
}

export default ExtraLinksNav;