import React from "react"

import Header from '../components/header'
// import Footer from '../components/footer'
// import { CookieBanner } from '../components/cookie-banner'

import '../styles/main.scss';

const Layout=({ children }) => {
    return(
        <>
            <Header />
            <main>
                { children }
            </main>
            {/* <Footer /> */}
            {/* <CookieBanner /> */}
        </>
    )
}

export default Layout