import React from "react";
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

import styles from  './styles.module.scss';

const HomeScroll=({ className }) => {
    return(
        <nav aria-label="Home Page Section Scroll" className={`${styles.homeScroll}${className ? ' '+className : ''}`}>
            <ul className={styles.homeScrollList}>
                <li className={styles.homeScrollListItem}>
                    <Link activeClass={styles.homeScrollListItemLinkActive} className={`d-block text-right ${styles.homeScrollListItemLink}`} spy={true} smooth={true} duration={500} to="Project1">
                        01/
                    </Link>
                </li>
                <li className={styles.homeScrollListItem}>
                    <Link activeClass={styles.homeScrollListItemLinkActive} className={`d-block text-right ${styles.homeScrollListItemLink}`} spy={true} smooth={true} duration={500} to="Project2">
                        02/
                    </Link>
                </li>
                <li className={styles.homeScrollListItem}>
                    <Link activeClass={styles.homeScrollListItemLinkActive} className={`d-block text-right ${styles.homeScrollListItemLink}`} spy={true} smooth={true} duration={500} to="Project3">
                        03/
                    </Link>
                </li>
                <li className={styles.homeScrollListItem}>
                    <Link activeClass={styles.homeScrollListItemLinkActive} className={`d-block text-right ${styles.homeScrollListItemLink}`} spy={true} smooth={true} duration={500} to="Project4">
                        04/
                    </Link>
                </li>
                <li className={styles.homeScrollListItem}>
                    <Link activeClass={styles.homeScrollListItemLinkActive} className={`d-block text-right ${styles.homeScrollListItemLink}`} spy={true} smooth={true} duration={500} to="Project5">
                        05/
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default HomeScroll;