import React from "react";

import styles from  './styles.module.scss';

import logo from '../../images/general/logo.svg';

const Header=() => {
    return(
        <header className={`p-1 ${styles.header}`}>
            <img className={ styles.headerLogo } src={logo} alt="" />
        </header>
    )
}

export default Header;
